import React from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';
import { find } from 'lodash';
import { useIntl } from 'react-intl';

import { useLocale, LOCALES } from 'hooks/useLocale';
import { Heading5, Heading6 } from '../elements/Typography';
import BaseButton from '../forms/Button';
import { Div } from '../utils/Helper';
import { moment } from '../../utils/momentUtils';
import messages from './i18n/day';

const Button = styled(BaseButton)`
  background-color: ${props => props.styledBg};
  color: ${props => props.styledBg && 'white'};
`;

const ButtonLabel = styled.div`
  margin-top: ${props => (props.showPerson ? '-7px' : '0px')};
`;

const PersonDiv = styled.div`
  display: table;
  font-size: 11px;
  font-weight: bold;
  margin: 0 auto;
  padding: 0px 10px;
  border-radius: 4px;
  background-color: #eae6fe;
  color: #6e52ff;

  ${props =>
    props.disabled &&
    `
    color: rgba(20,44,43,0.2) !important;
  `}
`;

const CustomReactTooltip = styled(ReactTooltip).attrs({
  className: 'custom-tooltip',
})`
  &.custom-tooltip {
    border: solid 1px #e5e5e5;
    padding-top: 20px;
    border-radius: 6px;
    min-width: 205px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: bold;

    transition: visibility 0s 0.375s, opacity 0.375s ease-in-out !important;

    &.show {
      transition: visibility 0s 0s, opacity 0.375s ease-in-out !important;
      opacity: 1;
    }
  }
`;

const ParticipantItem = styled.span`
  font-size: 15px;
  display: block;
  width: 100%;
  margin-bottom: ${props => (props.last ? '0' : '12px')};
  text-align: left;
  font-weight: normal;
`;

const GuestHeader = styled.span`
  display: block;
  height: 19px;
  font-family: NotoSansJP;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #68878d;
`;

export function SingleDate({
  day,
  date,
  isDateEarlier,
  getJapaneseDay,
  getEnglishDay,
}) {
  const [locale] = useLocale();

  return (
    <Div
      display={['flex', 'block']}
      alignItems="baseline"
      textAlign={[null, 'center']}
      width="100%"
    >
      <Div width={[null, '100%']}>
        <Heading5
          fontSize={['28px', '42px']}
          color={`${
            (isDateEarlier(day, moment()) === 'earlier' &&
              'rgba(20, 44, 43, 0.2)') ||
            (isDateEarlier(day, moment()) === 'equal' && '#00bbb5')
          }`}
        >
          {date}
        </Heading5>
      </Div>
      <Div ml={['7px', '0px']} mb="10px" width={[null, '100%']}>
        <Heading6
          color={`${
            (isDateEarlier(day, moment()) === 'earlier' &&
              'rgba(20, 44, 43, 0.2)') ||
            (isDateEarlier(day, moment()) === 'equal' && '#00bbb5')
          }`}
        >
          {locale === LOCALES.JA
            ? getJapaneseDay(day.day())
            : getEnglishDay(day.day())}
        </Heading6>
      </Div>
    </Div>
  );
}

SingleDate.defaultProps = {
  day: null,
  date: [],
};

SingleDate.propTypes = {
  day: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  date: PropTypes.number,
  getJapaneseDay: PropTypes.func.isRequired,
  getEnglishDay: PropTypes.func.isRequired,
  isDateEarlier: PropTypes.func.isRequired,
};

export const TimeSlots = ({
  hoursOfTheDay,
  timeSlots,
  day,
  handleChange,
  duration,
  isMultiTime,
  multiguestCalendarDetail,
}) => {
  const selectedSlots = useSelector(state => state.selectedSlots.slots);
  const schedulableFrom = moment(
    multiguestCalendarDetail.schedulableFrom,
    'YYYY-MM-DD',
  );
  const schedulableUntil = moment(
    multiguestCalendarDetail.schedulableUntil,
    'YYYY-MM-DD',
  );
  const intl = useIntl();

  function guestSlot(slot, time) {
    const result = { selectedGuests: [] };
    result.isSchedulable = slot.isBetween(
      schedulableFrom,
      schedulableUntil,
      '',
      '[]',
    );

    if (multiguestCalendarDetail.guests?.length) {
      multiguestCalendarDetail.guests.map(guest => {
        return guest.candidateSlots.map(candidateSlot => {
          if (
            time === moment(candidateSlot.startAt).format('HH:mm') &&
            slot.isSame(moment(candidateSlot.startAt, 'YYYY-MM-DD'))
          ) {
            result.isMultiguest = true;
            result.selectedGuests = result.selectedGuests.concat(guest);
          }
          return result;
        });
      });
    }
    return result;
  }

  const days = hoursOfTheDay.map(currentTime => {
    const endTime = moment(currentTime, 'HH:mm')
      .add(duration, 'minutes')
      .format('HH:mm');
    return (
      <Button
        outlined
        invertOnHover
        disabled={!timeSlots.includes(currentTime)}
        fontSize={['20px', '16px', '20px']}
        borderRadius="3px"
        width={['130px', '100%']}
        minWidth={['130px', '100%']}
        height={['51px', '63px']}
        boxShadow="0 1px 6px 0 #d2e9e7"
        onClick={e => handleChange(e, day, currentTime)}
        key={currentTime}
        margin={['0px 5px', '5px 0px']}
      >
        <div className="shown">{currentTime}</div>
        <div className="hidden">{endTime}</div>
      </Button>
    );
  });

  // eslint-disable-next-line array-callback-return
  const multiTimeSlots = hoursOfTheDay.map(currentTime => {
    if (isMultiTime) {
      const endTime = moment(currentTime, 'HH:mm')
        .add(duration, 'minutes')
        .format('HH:mm');
      const timeSlotId = `timeSlot-${day.format('YYYY-MM-DD')}_${currentTime}`;
      const isSelected = find(selectedSlots, { id: timeSlotId }) && true;
      const { isMultiguest, selectedGuests, isSchedulable } = guestSlot(
        day,
        currentTime,
      );
      const disabled = !timeSlots.includes(currentTime) || !isSchedulable;
      const showPerson = multiguestCalendarDetail?.guests?.length > 0;
      return (
        <Div key={currentTime} margin={['0px 5px', '5px 0px']}>
          <Button
            outlined
            invertOnHover
            outlinedColor={isMultiTime ? '#6e52ff' : '#00bbb5'}
            styledBg={isSelected && '#6e52ff'}
            disabled={disabled}
            fontSize={['20px', '15px', '20px']}
            borderRadius="3px"
            width={['95px', '100%']}
            height={['60px', '63px']}
            boxShadow="0 1px 6px 0 #d2e9e7"
            onClick={e => handleChange(e, day, currentTime, timeSlotId)}
            data-for={`person-tooltip-${timeSlotId}`}
            data-tip
            data-event="mouseover"
            data-event-off="mouseout"
          >
            <div className="shown">{currentTime}</div>
            <div className="hidden">{endTime}</div>
            <ButtonLabel showPerson={showPerson}>
              {showPerson && isMultiTime && !disabled && (
                <>
                  <PersonDiv disabled={disabled}>
                    {(() => {
                      if (isMultiguest) {
                        return selectedGuests.length > 1
                          ? intl.formatMessage(messages.persons, {
                              guests: selectedGuests.length,
                            })
                          : intl.formatMessage(messages.person, {
                              guests: selectedGuests.length,
                            });
                      }
                      return '';
                    })()}
                  </PersonDiv>
                  {isMultiguest && (
                    <CustomReactTooltip
                      isCapture
                      html
                      id={`person-tooltip-${timeSlotId}`}
                      place="bottom"
                      effect="solid"
                      textColor="black"
                      backgroundColor="white"
                    >
                      {/* This is needed to show allow the tooltip receive a component instead of just a string.  */}
                      {ReactDOMServer.renderToString(
                        <div>
                          <GuestHeader>
                            {intl.formatMessage(messages.availableParticipants)}
                          </GuestHeader>
                          {selectedGuests.map((guest, index) => (
                            <ParticipantItem
                              key={guest.id}
                              last={index === selectedGuests.length - 1}
                            >
                              {guest.companyName
                                ? `${guest.name} 様: ${guest.companyName}`
                                : `${guest.name} 様`}
                            </ParticipantItem>
                          ))}
                        </div>,
                      )}
                    </CustomReactTooltip>
                  )}
                </>
              )}
            </ButtonLabel>
          </Button>
        </Div>
      );
    }
    return null;
  });

  return (
    <Div
      noScrollbar
      overflowX="auto"
      display="flex"
      flexDirection={['row', 'column']}
      padding={[null, '0px 20px']}
      justifyContent={[null, 'space-between']}
      width="100%"
    >
      {isMultiTime ? multiTimeSlots : days}
    </Div>
  );
};

TimeSlots.defaultProps = {
  day: null,
  timeSlots: [],
  hoursOfTheDay: [],
  isMultiTime: false,
  multiguestCalendarDetail: [],
};

TimeSlots.propTypes = {
  day: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  handleChange: PropTypes.func.isRequired,
  timeSlots: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  hoursOfTheDay: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  duration: PropTypes.number.isRequired,
  isMultiTime: PropTypes.bool,
  multiguestCalendarDetail: PropTypes.instanceOf(Array),
};
