import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  formValueSelector,
  reduxForm,
  Field,
  FieldArray,
  FormSection,
  destroy,
} from 'redux-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Checkbox } from '@d-lighted/design-system';
import { cloneDeep, find, sortBy, snakeCase } from 'lodash';
import createHumps from 'lodash-humps/lib/createHumps';
import { FormattedMessage, useIntl } from 'react-intl';

import { moment } from 'utils/momentUtils';
import { useValidation } from 'utils/validation';
import { _post } from 'utils/api';
import { pushEvent, events } from 'utils/GTM';
import { Heading2 } from 'components/elements/Typography';
import DateTimeCard from 'components/forms/DateTimeCard';
import Button from 'components/forms/Button';
import Loader from 'components/layouts/Loader';
import Footer from 'components/layouts/Footer';
import {
  defaultRowMargin,
  defaultColPadding,
  Col,
  Row,
  StyledLink,
  Div,
} from 'components/utils/Helper';
import { isMobileDevice } from 'utils/mobileUtils';
import { isPageTypeIrregular } from 'utils/calendarUtils';
import { useAvailableTimeSlots } from 'hooks/useAvailableTimeSlots';
import CheckBoxCase from '../modules/CheckBoxCase';
import DateCase from '../modules/DateCase';
import RadioButtonCase from '../modules/RadioButton';
import TextAreaCase from '../modules/TextArea';
import TextFieldCase from '../modules/TextField';
import DropdownCase from '../modules/Dropdown';
import BlockOfTextCase from '../modules/BlockOfText';
import VisitorFieldsGroup from '../modules/VisitorFieldsGroup';
import { FormatCustomField } from '../utils/dataFormatter';
import messages from './i18n/confirmation';

const snakes = createHumps(snakeCase);

function Confirmation(props) {
  const dispatch = useDispatch();
  const { actions } = useAvailableTimeSlots();
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const intl = useIntl();
  const {
    checked,
    required,
    email,
    phoneNo,
    requiredCheckboxes,
    maxLength255,
  } = useValidation();
  const { selectedHours, pastedVisitors, history, handleSubmit, feature } =
    props;

  const calendarDetail = useSelector(state => state.calendarDetail);
  const { timezone } = useSelector(state => state.timezone);
  const { pntpAllowed } = useSelector(state => state.pntp);
  const { slots } = useSelector(state => state.selectedSlots);

  const {
    uid,
    confirmationMessage,
    bookingCalendarCustomFields,
    companyIsRequired,
    companyIsHidden,
    companyName,
    guestNameFieldIsRequired,
    guestNameFieldIsHidden,
    multipleguestsAllowed,
    privacyPolicyUrl,
    useOwnPrivacyPolicy,
    allowSplitGuestName,
  } = calendarDetail;

  const isIrregular = isPageTypeIrregular(calendarDetail, feature);

  let customFields = cloneDeep(bookingCalendarCustomFields) || [];
  customFields = sortBy(customFields, [
    function comparator(field) {
      return field.rowOrder;
    },
  ]);

  useEffect(() => {
    window.scroll(0, 0);
    pushEvent({ ...events.triggerPageView('Form') });
    actions.resetTimeSlotsFull();

    // This force moving backwards on refresh (Timezone and custom fields are not loading properly on refresh)
    if (!calendarDetail || !selectedHours || timezone.label === '') {
      history.push(
        `/${params.calendar_alias}${
          params.duration ? `/${params.duration}` : ''
        }`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { year, month, date, day, time, nextHour, duration } =
    selectedHours || {
      year: '202?',
      month: '??',
      date: '??',
      day: '??',
      time: '??:??',
      nextHour: '??:??',
      duration: '??',
    };

  const showCustomPrivacyPolicy = privacyPolicyUrl && useOwnPrivacyPolicy;
  const defaultPrivacyPolicy = 'https://help.receptionist.jp/?p=402';

  const registerClickLinkHelpAction = () => {
    const param = showCustomPrivacyPolicy ? 'custom' : '/?p=402';
    pushEvent({
      ...events.onClickLinkHelp(param),
    });
  };

  const getHiddenFieldValues = () => {
    const hiddenFields = customFields.filter(
      field => field.fieldType === 'hidden_field',
    );
    const result = hiddenFields.map(field =>
      FormatCustomField({
        fieldType: field.fieldType,
        uid: field.uid,
        labelName: field.labelName,
        value: field.bookingCalendarCustomFieldInputs[0]?.inputValue,
      }),
    );
    return result;
  };

  const onSubmit = async values => {
    let formattedCustomFieldData = [];
    if (values.customFields) {
      formattedCustomFieldData = Object.keys(values.customFields).flatMap(
        key => {
          const field = find(customFields, { uid: key });
          if (field.fieldType === 'check_box') {
            return values.customFields[key].map(value => {
              return FormatCustomField({
                fieldType: field.fieldType,
                uid: key,
                labelName: field.labelName,
                value,
              });
            });
          }
          if (field.fieldType === 'dropdown') {
            return FormatCustomField({
              fieldType: field.fieldType,
              uid: key,
              labelName: field.labelName,
              value: values.customFields[key].value,
            });
          }
          return FormatCustomField({
            fieldType: field.fieldType,
            uid: key,
            labelName: field.labelName,
            value: values.customFields[key],
          });
        },
      );
    }
    formattedCustomFieldData = formattedCustomFieldData.concat(
      getHiddenFieldValues(),
    );
    setLoading(true);
    // TODO: Change this
    document.getElementsByClassName('submit_button')[0].disabled = true;
    const begin = moment.tz(
      `${year}-${month}-${date} ${time}`,
      'YYYY-MM-DD hh:mm',
      timezone.code,
    );
    const dataForApi = {
      begin_at: begin.toISOString(true),
      end_at: moment(begin).add(duration, 'm').toISOString(true),
      visitors: snakes(values.visitors),
      booking_calendar_custom_field_inputs: formattedCustomFieldData,
      alias: params.calendar_alias,
      time_zone: timezone.code,
    };
    _post(
      `/booking_calendars/${uid}/${
        isIrregular ? 'irregular_appointments' : 'appointments'
      }`,
      dataForApi,
    ).then(response => {
      dispatch(destroy('Booking'));
      setLoading(false);
      if (response.status < 300) {
        history.replace({
          pathname: `/${params.calendar_alias}${
            isIrregular ? '' : `/${params.duration}`
          }/completed`,
          search: `pntp=${pntpAllowed}`,
          state: {
            ...values,
            selectedHours,
            message: confirmationMessage,
            appointment: response.data,
          },
        });
      } else {
        history.replace({
          pathname: '/error',
          state: { ...values, message: response.data.message, back: true },
        });
      }
    });
  };

  const handleGoBackToForm = e => {
    e.preventDefault();
    pushEvent({ ...events.goBackToForm() });
    props.history.goBack();
  };

  if (!isIrregular) if (!selectedHours || timezone.label === '') return null;

  if (loading) return <Loader />;

  const isMobile = isMobileDevice();

  return (
    <>
      <Row mt="30px">
        <Col justifyContent="center" display="inline-flex">
          <form>
            <Row mb="1rem" ml={['0px', defaultRowMargin]}>
              <Col pb={['12px', '25px']} pl={['0px', defaultColPadding]}>
                <Div textAlign={['left', 'center']}>
                  <Heading2>
                    {isIrregular ? (
                      <FormattedMessage {...messages.formHeadingIrregular} />
                    ) : (
                      <FormattedMessage {...messages.formHeading} />
                    )}
                  </Heading2>
                </Div>
              </Col>
            </Row>
            {!isIrregular && (
              <Row alignItems="center" mb="25px">
                <DateTimeCard
                  year={year}
                  month={month}
                  date={date}
                  day={day}
                  time={time}
                  nextHour={nextHour}
                  label={intl.formatMessage(messages.dateTime)}
                  timezone={timezone}
                  slots={slots}
                />
              </Row>
            )}
            <FieldArray
              name="visitors"
              companyIsRequired={companyIsRequired}
              companyIsHidden={companyIsHidden}
              guestNameIsRequired={guestNameFieldIsRequired}
              guestNameIsHidden={guestNameFieldIsHidden}
              multipleguestsAllowed={multipleguestsAllowed}
              isMobile={isMobile}
              pastedVisitors={pastedVisitors}
              allowSplitGuestName={allowSplitGuestName}
              component={VisitorFieldsGroup}
            />
            <FormSection name="customFields">
              {customFields.map((field, index) => {
                let validateArray = [];
                switch (
                  field.bookingCalendarSettingBookingCalendarCustomField
                    .textFieldInputRestriction
                ) {
                  case 'email':
                    validateArray = [email];
                    break;
                  case 'phone_no':
                    validateArray = [phoneNo];
                    break;
                  default:
                    validateArray = [];
                    break;
                }
                const requiredValidates = [];
                if (
                  field.bookingCalendarSettingBookingCalendarCustomField
                    ?.inputRequired
                ) {
                  requiredValidates.push(
                    field.fieldType === 'check_box'
                      ? requiredCheckboxes
                      : required,
                  );
                }
                validateArray = [...requiredValidates, ...validateArray];
                switch (field.fieldType) {
                  case 'text_field': {
                    return (
                      <TextFieldCase
                        key={`${field.uid}`}
                        id={`${field.uid}-${index}`}
                        field={field}
                        validateArray={[...validateArray, maxLength255]}
                        isRequired={
                          field.bookingCalendarSettingBookingCalendarCustomField
                            .inputRequired
                        }
                      />
                    );
                  }
                  case 'text_area': {
                    return (
                      <TextAreaCase
                        key={`${field.uid}`}
                        id={`${field.uid}-${index}`}
                        field={field}
                        validateArray={validateArray}
                        isRequired={
                          field.bookingCalendarSettingBookingCalendarCustomField
                            .inputRequired
                        }
                      />
                    );
                  }
                  case 'radio_button': {
                    return (
                      <RadioButtonCase
                        key={`${field.uid}`}
                        id={`${field.uid}-${index}`}
                        field={field}
                        validateArray={validateArray}
                        isRequired={
                          field.bookingCalendarSettingBookingCalendarCustomField
                            .inputRequired
                        }
                      />
                    );
                  }
                  case 'check_box': {
                    return (
                      <CheckBoxCase
                        key={`${field.uid}`}
                        id={`${field.uid}-${index}`}
                        field={field}
                        validateArray={validateArray}
                        isRequired={
                          field.bookingCalendarSettingBookingCalendarCustomField
                            .inputRequired
                        }
                      />
                    );
                  }
                  case 'dropdown': {
                    return (
                      <DropdownCase
                        key={`${field.uid}`}
                        id={`${field.uid}-${index}`}
                        field={field}
                        validateArray={validateArray}
                        isRequired={
                          field.bookingCalendarSettingBookingCalendarCustomField
                            .inputRequired
                        }
                      />
                    );
                  }
                  case 'date': {
                    return (
                      <DateCase
                        key={`${field.uid}`}
                        id={`${field.uid}-${index}`}
                        field={field}
                        validateArray={validateArray}
                        isRequired={
                          field.bookingCalendarSettingBookingCalendarCustomField
                            .inputRequired
                        }
                      />
                    );
                  }
                  case 'block_of_text': {
                    return (
                      <BlockOfTextCase key={`${field.uid}`} field={field} />
                    );
                  }
                  default: {
                    return null;
                  }
                }
              })}
            </FormSection>
            <Row mb="25px">
              <Col
                display={[null, 'flex']}
                justifyContent={[null, 'center']}
                width={[null, '100%']}
                minWidth={[null, '376px']}
              >
                <div>
                  <Field
                    useArrayValue={false}
                    trueFor="agree"
                    component={Checkbox}
                    id="agree"
                    name="agree"
                    validate={[checked]}
                  >
                    {showCustomPrivacyPolicy ? (
                      <FormattedMessage
                        {...messages.customPrivacyPolicy}
                        values={{
                          br: <br />,
                          c: companyName,
                          a: function userPrivacyLink(chunks) {
                            return (
                              <StyledLink
                                tabIndex="-1"
                                href={privacyPolicyUrl}
                                target="_blank"
                                color="#00bbb5"
                                onClick={() => registerClickLinkHelpAction()}
                              >
                                {chunks}
                              </StyledLink>
                            );
                          },
                          aa: function productPrivacyLink(chunks) {
                            return (
                              <StyledLink
                                tabIndex="-1"
                                href={defaultPrivacyPolicy}
                                target="_blank"
                                color="#00bbb5"
                                onClick={() => registerClickLinkHelpAction()}
                              >
                                {chunks}
                              </StyledLink>
                            );
                          },
                        }}
                      />
                    ) : (
                      <FormattedMessage
                        {...messages.privacyPolicy}
                        values={{
                          a: function defaultPrivacyLink(chunks) {
                            return (
                              <StyledLink
                                tabIndex="-1"
                                href={defaultPrivacyPolicy}
                                target="_blank"
                                color="#00bbb5"
                                onClick={() => registerClickLinkHelpAction()}
                              >
                                {chunks}
                              </StyledLink>
                            );
                          },
                        }}
                      />
                    )}
                  </Field>
                </div>
              </Col>
            </Row>
            {!isIrregular && (
              <Row display={['block', 'none']} mb="1rem">
                <Col>
                  <Button
                    type="submit"
                    outlined
                    width="100%"
                    onClick={e => handleGoBackToForm(e)}
                  >
                    <FormattedMessage {...messages.reselectBtn} />
                  </Button>
                </Col>
              </Row>
            )}
            <Div
              width={[null, '100%']}
              minWidth={[null, '376px']}
              mt={[null, '19px']}
              justifyContent={[null, 'center']}
              alignItems={[null, 'center']}
              display={[null, 'flex']}
              mx={[null, '-18px']}
            >
              {!isIrregular && (
                <Div display={['none', 'block']} pr={3}>
                  <Button
                    outlined
                    width="149px"
                    onClick={e => handleGoBackToForm(e)}
                  >
                    <FormattedMessage {...messages.reselectBtn} />
                  </Button>
                </Div>
              )}
              <Button
                className="submit_button"
                width={isIrregular ? '100%' : ['100%', '211px']}
                onClick={handleSubmit(values => onSubmit(values))}
              >
                {isIrregular ? (
                  <FormattedMessage {...messages.confirmBtnIrregular} />
                ) : (
                  <FormattedMessage {...messages.confirmBtn} />
                )}
              </Button>
            </Div>
          </form>
        </Col>
      </Row>
      <Footer />
    </>
  );
}

const ConfirmationPage = connect(state => {
  const selector = formValueSelector('BookingDate');
  const bookingSelector = formValueSelector('Booking');
  return {
    selectedHours: selector(state, 'selectedHours'),
    pastedVisitors: bookingSelector(state, 'pastedVisitors'),
  };
})(
  reduxForm({
    form: 'Booking',
    initialValues: { visitors: [{}], agree: false },
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
  })(Confirmation),
);

export default ConfirmationPage;

Confirmation.defaultProps = {
  selectedHours: {},
  pastedVisitors: null,
};

Confirmation.propTypes = {
  feature: PropTypes.string.isRequired,
  selectedHours: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  history: ReactRouterPropTypes.history.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pastedVisitors: PropTypes.string,
};
